import React, { useState } from 'react'
import { ReactComponent as Logo } from './MiningLogo (1).svg'
import { toast } from 'react-toastify'
function Signin() {
  const [miningId, setMiningId] = useState('')
  const notify = (type, message) => {
    if (type === 'info') {
      toast.info(message)
    }
    if (type === 'success') {
      toast.success(message)
    }
    if (type === 'warn') {
      toast.warn(message)
    }
    if (type === 'error') {
      toast.error(message)
    }
  }

  const submit = () => {
    notify('error', 'An error occurred, try again later.')
  }
  return (
    <div className='signin'>
      <div className='modal'>
        <Logo />
        <h1>Welcome</h1>
        <p>Log in to UKN Mining Pool to continue to Pool Website.</p>
        <input
          type='text'
          name=''
          id=''
          placeholder='Username'
          value={miningId}
        />
        <input
          type='text'
          name=''
          id=''
          placeholder='Full Name'
          value={miningId}
        />
        <input type='tel' name='' id='' placeholder='Phone' value={miningId} />
        <input
          type='email'
          name=''
          id=''
          placeholder='Email'
          value={miningId}
        />
        <div className='button'>
          <button onClick={submit}>Continue</button>
        </div>
      </div>
    </div>
  )
}

export default Signin
