import React from 'react'
import './styles.scss'
import { ReactComponent as Logo } from './MiningLogo.svg'
import { useNavigate } from 'react-router-dom'

function Landing() {
  const navigate = useNavigate()
  return (
    <div className='land'>
      <div className='top'>
        <Logo />
        <button onClick={() => navigate('/login')}>LOGIN</button>
      </div>
      <div className='texts'>
        <div className='texts-cover'>
          <h1>UKN Mining Pool</h1>
          <p>
            Finally, a US-based, truly institutional grade mining pool brought
            to you by Foundry. Built from the ground up, we are geared towards
            providing best in class service with a focus on large miners.
          </p>
          <div className='butt'>
            <button onClick={() => navigate('/login')}>LOGIN</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
