import React from 'react'
import './styles.scss'
import { ReactComponent as Logo } from './MiningLogo.svg'
import { useNavigate } from 'react-router-dom'

function PreLogin() {
  const navigate = useNavigate()
  return (
    <div className='prelogin'>
      <Logo />
      <div className='fi'>
        <button onClick={() => navigate('/sign-in')}>
          Create a UKN Mining pool account
        </button>
      </div>
    </div>
  )
}

export default PreLogin
