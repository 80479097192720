import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Landing from './pages/Landing'
import PreLogin from './pages/PreLogin'
import Signin from './pages/Signin'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position='top-right'
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/login' element={<PreLogin />} />
        <Route path='/sign-in' element={<Signin />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
